import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function purchaseinbounditemUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
   // { key: 'id', label: 'inbounditem_id', sortable: true },
    /*{ key: 'inbound_id', label: 'ID', sortable: true },
   { key: 'inbound_no', label: '入库单编号', sortable: true },
   { key: 'is_sel', label: '是否选中', sortable: true },
   { key: 'order_id', label: '采购单ID', sortable: true },
   { key: 'orderitem_id', label: '采购单产品明细ID', sortable: true },
   { key: 'product_id', label: '商品ID', sortable: true },
   { key: 'category_id', label: '分类ID', sortable: true },
   { key: 'category_name', label: '分类名称', sortable: true },
   { key: 'brand_id', label: '品牌ID', sortable: true },
   { key: 'brand_name', label: '品牌名称', sortable: true },*/

    { key: 'product_name', label: '商品名称'},
    { key: 'purchase_team', label: '销售权'},
    { key: 'product_code', label: '编码' },
    // { key: 'is_gift', label: '是否赠品', sortable: true },
    // { key: 'qty', label: '计划入库', sortable: true },
    // { key: 'trueqty', label: '已入库', sortable: true },
    // { key: 'inventory_type', label: '盘点类型', sortable: true},
    // { key: 'state', label: '状态', sortable: true },
    // { key: 'add_time', label: '添加时间', sortable: true },
    // { key: 'modify_time', label: '修改时间', sortable: true },
        { key: 'actions', label: '操作' },
  ]

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  return {
    refListTable,
    tableColumns,
    refetchData,
  }
}
