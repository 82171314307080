<template>
  <div>
    <b-card
        no-body
        class="mb-0"
    >
      <b-modal
          id="modal-select-warehouse"
          hide-footer
          centered
          size="xl"
          title="选择仓库"
      >
        <warehouse-list
            ref="warehouseSelect" :type="1" v-on:table="fromChildren($event,'modal-select-warehouse')"
        >
        </warehouse-list>
      </b-modal>
      <b-modal
          id="modal-select-product"
          ok-only
          ok-title="确认"
          @ok="onSelectProducts"
          cancel-title="取消"
          centered
          size="xl"
          title="选择商品"
      >
        <product-select
            ref="myProductSelect"
        >
        </product-select>
      </b-modal>
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="仓库"
                label-for="warehouse_name"
                class="mb-1 required"
            >
              <v-select
                  id="warehouse_name"
                  :options="warehouseOptions"
                  v-model="state.warehouse_id"
                  :clearable="false"
                  :reduce="option => parseInt(option.value)"
              >
              </v-select>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <xy-date-picker label-cols="2" label="预计到达时间" :val.sync="state.arrival_time"
                            rules="required" format="date" :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                            id="refund_time"> </xy-date-picker>
          </b-col>

          <b-col
              cols="12"
              md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  variant="primary"
                  v-b-modal.modal-select-product
              >
                <span class="text-nowrap">添加产品</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>


      <b-table
          ref="refListTable"
          class="position-relative"
          :items="itemList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="未找到记录"
      >

        <template #cell(product_name)="data">
<!--          <feather-icon
              :icon="data.detailsShowing ? 'MinusSquareIcon' : 'PlusSquareIcon'"
              @click="toggleDetail(data)"
              class="mr-1"
          />-->
          [#{{ data.item.product_id }}]{{ data.item.product_name }}
        </template>

        <template #cell(inventory_type)="data">
          {{ getCodeLabel('inbound_inventory_type', data.item.inventory_type) }}
        </template>

        <template #cell(purchase_team)="data">
          <v-select
              :options="getCodeOptions('purchase_team')"
              v-model="data.item.purchaseTeam"
              name="user_level"
              class="select-size-sm"
              placeholder="请选择销售权团队"
              style="width: 200px"
          />
        </template>

        <template #row-details="data">
          <b-card>
            <inbound-form
                :ref="'inboundForm'+data.index" :inbound-item="data.item" :warehouse_id="state.warehouse_id"
                v-on:refresh="hideAndRefetchData(data)"
            >
            </inbound-form>
          </b-card>

        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="row">
            <span>
               <xy-pop-confirm title="确认删除？" @confirm="del(row.index)">
                <xy-link>删除</xy-link>
              </xy-pop-confirm>
            </span>
        </template>
      </b-table>

      <div class="m-2">
        <b-row>
          <b-col md="12">
            <xy-textarea label-cols="1" id="remark" label="备注" :val.sync="state.memoWh" />
          </b-col>
          <b-col md="12">
            <b-form-group
                label-cols="1"
                label-cols-lg="1"
                label="附件"
                label-for="attachments"
                label-size="sm"
                class="mb-1"
            >
              <attachment-upload theme="files"
                                 attachment_id="attachmentsWh"
                                 :id="state.attachmentsWh"
                                 object_type="purchase_inbound"
                                 placeholder=""
                                 @change="onUploaded"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-button
            variant="primary"
            class="mr-1"
            @click="save"
            v-if="saveShow"
        >
          入库完成
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BAlert,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormInput,
  BLink,
  BPagination,
  BRow,
  BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import {avatarText, getCode, getCodeColor, getCodeLabel, getCodeOptions, isEmpty} from '@core/utils/filter'
import ProductSelect from '@/views/apps/product/product-purchase/ProductSelect'
import otherInboundUseList from './otherInboundUseList'
import purchaseinbounditemStore from '@/views/apps/purchaseinbounditem/purchaseinbounditemStore'
import { useToast } from 'vue-toastification/composition'
import { useRouter } from '@core/utils/utils'
import InboundForm from './inboundForm'
import StockInboundItemList from '@/views/apps/stockinbounditem/inboundItem-modal/StockInboundItemList'
import WarehouseList from '@/views/apps/warehouse/warehouse-modal/WarehouseList'
import XyLink from '@/views/components/xy/XyLink'
import XyPopConfirm from '@/views/components/xy/XyPopConfirm'
import { getUserData } from '@/auth/utils'
import XyTextarea from '@/views/components/xy/XyTextarea'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import XyDatePicker from '@/views/components/xy/XyDatePicker'

export default {
  props: {
    inboundId: {
      type: Number,
    },
  },
  components: {
    XyDatePicker,
    AttachmentUpload,
    XyTextarea,
    XyPopConfirm,
    XyLink,
    WarehouseList,
    ProductSelect,
    StockInboundItemList,
    InboundForm,
    BCard,
    BAlert,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormCheckbox,
    vSelect,
  },
  data() {
    return {
      maxFlag: false,
      saveShow:true,
      state: {
        warehouse_id:undefined,
        memoWh:undefined,
        attachmentsWh:undefined,
      },
      itemList: [],
    }
  },
  computed:{
    warehouseOptions(){
      return ["1","2"].includes(this.level)?getCodeOptions('warehouse'):getCodeOptions('warehouse').filter(option=>getCodeLabel('user_warehouse',this.user_id).includes(option.value))
    }
  },
  methods: {
    selectable(option){
      return getCodeLabel('user_warehouse',this.user_id).includes(option.value)
    },
    del(index) {
      this.itemList.splice(index, 1)
    },
    fromChildren(data, modal) {
      if (modal === 'modal-select-warehouse') {
        this.responsiveSet('warehouse_id', data['warehouse_id'])
        this.responsiveSet('warehouse_name', data['warehouse_name'])
      }
      this.$bvModal.hide(modal)
    },
    responsiveSet(key, value) {
      this.$set(this.state, key, value)
    },
    showModal() {
      this.$refs['modal'].show()
    },
    toggleDetail(data) {
      data.toggleDetails()
    },
    onSelectProducts() {
      let selected = this.$refs.myProductSelect.getSelected()

      selected.forEach(item => {
        const newData = {
          id: 'new' + (this.itemList.length - 1),
          product_id: item.product_id,
          product_name: item.name,
          product_code: item.code,
          inboundDetails: [{ status: 0 }],
          product: item,
          _showDetails: true
        }
        this.itemList.push(newData)
      })

    },
    async validateAll() {
      let count = 0
      let items = this.itemList
      for (let i = 0; i < items.length; i++) {
        if (await this.$refs[`inboundForm${i}`].$refs.observer.validate()) {
          count++
        }
      }
      return count === items.length
    }
  },
  created() {
    this.state.warehouse_id = this.warehouseOptions.length==0?null:parseInt(this.warehouseOptions[0].value)
  },
  setup: function (props) {

    const toast = useToast()
    const {
      route,
        router
    } = useRouter()
    let inbound_id
    if (props.inboundId != null) {
      inbound_id = props.inboundId
    } else {
      inbound_id = route.value.query.id || 0
    }
    const warehouseId = ref(parseInt(route.value.query.warehouseId) || 0)
    // Register module
    if (!store.hasModule('purchaseinbounditem')) store.registerModule('purchaseinbounditem', purchaseinbounditemStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('purchaseinbounditem')) store.unregisterModule('purchaseinbounditem')
    })

    const save = async function () {
      this.saveShow = false
      if (isEmpty(this.state.warehouse_id)){
        toast.error("请选择仓库")
        this.saveShow = true
        return
      }
      if (this.itemList.length === 0){
        toast.error("请填写明细")
        this.saveShow = true
        return
      }
      if (!this.state.arrival_time){
        toast.error("请填写预计到达时间")
        this.saveShow = true
        return
      }
      const success = await this.validateAll()
      if (!success){
        toast.error("请按照提示正确填写")
        this.saveShow = true
        return
      }
      for (let i = 0; i < this.itemList.length; i++) {
        if (isEmpty(this.itemList[i].purchaseTeam)){
          toast.error("请填写销售权")
          this.saveShow = true
          return
        }
      }



      const params = {...this.state,item_list:[]}
      this.itemList.forEach(item =>{
        let temp = {}
        temp.product_id = item.product_id
        temp.inbound_details = item.inboundDetails
        temp.purchase_team_id = item.purchaseTeam.value
        params.item_list.push(temp)
      })
      store.dispatch('purchaseinbounditem/otherInbound', params)
          .then(res => {
            this.saveShow = true
            if (res.data.code === 0) {
              toast.success(res.data.data.msg)
              router.push({name: 'apps-purchaseinbound-warehouseList',query:{supplier_id:4184}})
            } else {
              toast.error(res.data.data)
            }
          })
    }

    const hideAndRefetchData = function (data) {
      data.toggleDetails()
      refetchData()
    }

    const {
      tableColumns,
      refListTable,
      refetchData,

    } = otherInboundUseList({
      inbound_id: inbound_id
    })

    const { user_id } = getUserData()
    const { level } = getUserData()
    const onUploaded = function (id, attachment, result) {
      this.state[id] = result
    }
    return {
      tableColumns,
      refListTable,
      refetchData,

      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      save,
      isEmpty,
      warehouseId,
      hideAndRefetchData,
      user_id,
      level,
      onUploaded
    }
  },

}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
